import { useState, useEffect } from 'react';
import { CreatorsListUser } from 'lib/types/users';
import { QUOTES } from 'lib/constants';
import { TestimonialCard } from 'components/TestimonialCard';
import { useApi } from 'lib/contexts/ApplicationState';
import { ESServiceMeta } from 'server/api/types';

export const TestimonialsModule = () => {
  const API = useApi();
  const [testimonialUsers, setTestimonialUsers] = useState<CreatorsListUser[] | undefined>();

  useEffect(() => {
    async function fetchUsers(ids: number[]) {
      const users = await API.ignoreErrors(
        API.getTestimonials({ byUserId: ids, size: ids.length }),
        { data: [], meta: {} as ESServiceMeta },
      );
      setTestimonialUsers(users?.data);
    }

    if (!testimonialUsers) {
      fetchUsers(Object.keys(QUOTES).map((k) => Number(k)));
    }
  }, []);

  return (
    <div className="max-w-screen-xl mx-auto grid lg:grid-cols-2 gap-x-8 pb-8">
      {
        testimonialUsers?.map((user) => (
          <TestimonialCard key={user.id} user={user} text={QUOTES[user.id]} showLinks />
        ))
      }
    </div>
  );
};
